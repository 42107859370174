import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 992 992">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" >

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M577.999878,629.372925 
	C511.531281,629.373901 445.562653,629.375183 379.594055,629.373901 
	C372.667664,629.373779 372.661804,629.367126 372.661865,622.635315 
	C372.662720,541.673706 372.664062,460.712128 372.669983,379.750519 
	C372.670502,372.671844 372.685913,372.642944 379.798279,372.642242 
	C461.259064,372.634155 542.719910,372.670837 624.180542,372.543427 
	C628.895386,372.536072 630.444641,373.755707 630.436340,378.661987 
	C630.297791,460.289642 630.307190,541.917664 630.433044,623.545410 
	C630.440125,628.158813 629.037170,629.531067 624.476868,629.458740 
	C609.154602,629.215942 593.826050,629.370178 577.999878,629.372925 
M567.618225,509.993317 
	C566.840088,506.820068 566.124817,503.623505 564.443298,500.668121 
	C562.910522,500.929688 561.589417,501.062347 560.317810,501.388062 
	C554.584595,502.856506 553.675110,504.081146 555.228638,509.791473 
	C556.945374,516.101624 556.546753,522.618896 557.562256,529.013733 
	C558.795410,536.779297 556.861450,538.179871 548.916748,538.182007 
	C511.943359,538.192017 474.969940,538.182739 437.996613,538.242554 
	C431.965332,538.252319 431.186829,539.498352 433.583191,545.131897 
	C436.914124,552.962341 442.003113,559.218689 449.334442,563.888611 
	C469.382202,576.658569 492.434021,570.501648 505.138397,552.760681 
	C506.639313,550.664795 508.320129,549.716248 510.957184,549.759399 
	C520.115173,549.909363 529.276672,549.863281 538.436829,549.856567 
	C546.430847,549.850769 554.425232,549.833435 562.418701,549.746765 
	C565.437073,549.714050 567.387634,548.939148 567.942566,545.117615 
	C569.609070,533.641724 569.475342,522.262024 567.618225,509.993317 
M441.165771,501.667450 
	C440.528839,502.436768 439.732391,503.123077 439.281494,503.989258 
	C436.853851,508.652466 433.548645,510.256134 427.956635,509.598633 
	C420.568390,508.729858 412.987701,509.182007 405.510315,509.520813 
	C401.794739,509.689178 396.573273,510.245758 396.775208,514.898926 
	C396.966339,519.301758 401.687866,520.662720 406.001678,520.670105 
	C439.135864,520.726501 472.270081,520.799744 505.404205,520.780212 
	C510.685699,520.777100 512.027588,518.596069 509.820221,513.758728 
	C498.294647,488.501129 465.922272,477.324493 441.165771,501.667450 
M395.212433,475.792969 
	C401.992798,478.133514 404.907013,472.386017 408.852173,469.087982 
	C417.376984,461.961487 425.756500,454.668335 436.189911,450.354828 
	C453.522400,443.188965 471.373840,440.719727 489.870728,444.674744 
	C494.399689,445.643097 496.560272,443.239899 497.374573,439.462372 
	C498.192139,435.669647 495.119812,434.621063 492.292664,434.043243 
	C480.336548,431.599609 468.345886,430.909546 456.194855,432.906128 
	C442.374359,435.176971 429.304443,439.839172 418.061798,447.844116 
	C408.300171,454.794556 397.328430,461.573120 395.212433,475.792969 
M578.041748,447.427887 
	C573.789551,441.664062 569.288025,436.123352 563.593567,431.690338 
	C560.998291,429.669922 558.774780,426.122650 554.722961,429.442169 
	C554.304932,429.784698 553.055115,429.446625 552.376282,429.080963 
	C548.467651,426.975555 544.287354,425.529663 541.083069,422.005341 
	C536.118713,416.545135 534.448059,417.097748 532.193787,424.043457 
	C535.986694,424.218903 536.728088,428.094666 539.312805,429.904816 
	C557.402771,442.574097 571.209534,458.913513 579.977173,479.274445 
	C581.549438,482.925781 583.681824,483.552246 587.009949,482.246185 
	C588.242310,481.762634 589.479919,481.090210 590.759399,480.968140 
	C594.275085,480.632812 594.868652,479.010406 593.659119,475.907593 
	C589.778564,465.952515 585.213684,456.389252 578.041748,447.427887 
M506.373169,446.654358 
	C504.530548,450.577301 507.287048,452.030945 510.061981,453.363281 
	C525.233826,460.647797 537.161133,471.184174 544.202393,486.808289 
	C545.002563,488.583832 546.061829,491.233917 548.815796,490.591095 
	C552.173706,489.807251 555.742065,489.191284 558.200562,486.345673 
	C558.805847,485.645081 558.604675,484.761444 558.220276,483.989380 
	C556.522827,480.579681 555.459106,476.915649 553.492615,473.586884 
	C545.383057,459.859436 533.787720,450.031006 519.901367,442.562836 
	C511.977600,438.301331 510.767365,438.481720 506.373169,446.654358 
M587.979919,427.535767 
	C594.007080,434.917023 599.882874,442.417175 604.822754,450.004669 
	C610.064392,448.957611 607.982422,441.164124 614.084167,441.839111 
	C611.519653,436.701630 609.201538,431.351868 605.268188,427.076202 
	C597.490601,418.621735 590.324097,409.697754 579.864136,403.843933 
	C575.844788,401.594543 573.049316,400.368591 568.465271,402.045685 
	C570.816345,405.240417 568.200562,409.620422 571.444824,412.159393 
	C577.219299,416.678497 581.959473,422.273193 587.979919,427.535767 
M521.333740,608.282043 
	C523.353394,608.911011 525.078186,608.333252 527.037781,607.555176 
	C531.920044,605.616455 535.903259,602.515442 539.594543,598.955200 
	C547.656860,591.179016 554.074280,582.193787 558.923279,572.102905 
	C559.621582,570.649780 560.493408,568.971924 558.714661,567.596741 
	C555.453308,565.075378 549.804626,566.461609 547.644226,570.204712 
	C543.488342,577.405212 538.550781,583.958557 532.755981,589.996460 
	C527.033081,595.959473 520.296387,600.617371 512.913757,606.467529 
	C516.487793,606.090942 518.594666,606.960876 521.333740,608.282043 
M414.464935,608.082397 
	C423.307526,613.559326 424.860413,613.424744 430.813141,606.126038 
	C417.482666,597.898438 406.251526,587.381836 397.258240,574.696411 
	C389.261444,578.984863 389.806671,582.517456 394.117371,588.227051 
	C399.707062,595.630798 406.045654,602.451782 414.464935,608.082397 
z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
